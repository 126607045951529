import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";

import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import OTPInput from "react-otp-input";
import { arabicToEnglishNumbers } from "../../utils/validation";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useOtpVerficationMutation,
  usePostSignupMutation,
} from "../../service/Auth";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
  successToast,
} from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import useTranslation from "../../hooks/Translation";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
};

const otpStyle = {
  width: "16%",
  height: "60px",
  "box-sizing": "border-box",
  border: "1px solid #1d1d1d4f",
  padding: "10px",
};

type CountryType = {
  code: string;
  icon: string;
  id: string;
  countryCode: string;
};
const SignUp = ({ closeModal, setPhone }: props) => {
  const translation = useTranslation();

  const fcmToken = getFromStorage(STORAGE_KEYS.fcmToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [signupMutation, { isLoading }] = usePostSignupMutation();
  const [otpVerification, otpVerificationData] = useOtpVerficationMutation();

  const [selectedCountry, setSelectedCountry] = useState<CountryType>({
    code: "(SA) +966",
    icon: "/static/images/su_flag.webp",
    id: "2",
    countryCode: "+966",
  });

  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState<number>(30);
  const [email, setEmail] = useState(false);
  const [otpsend, setOtpSend] = useState(false);
  const [error, setError] = useState(false);
  console.log(otp, "otp");

  console.log(otpsend, "otpsendotpsend");

  const countryCodes = [
    {
      code: "(IND) +91",
      icon: "/static/images/in_flag.png",
      id: "1",
      countryCode: "+91",
    },
    {
      code: "(SA) +966",
      icon: "/static/images/su_flag.webp",
      id: "2",
      countryCode: "+966",
    },
  ];

  const handleChangeVendorSelect = (event: any, newValue: any) => {
    setSelectedCountry(newValue);
    formik.setFieldValue("phoneNumber", "");
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  const formik = useFormik({
    initialValues: {
      selectedCountry: "",
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required(translation.validations.required_field)
        .min(9, translation.validations.min_nine)
        .max(15, translation.validations.max_fifteen),
    }),
    onSubmit: async (values: any) => {
      formik.setSubmitting(true);
      // if (otpsend) {
      //   errorToast("Enter Your OTP1");
      //   return;
      // }

      let body = {
        phone: arabicToEnglishNumbers(formik.values.phoneNumber),
        countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
      };

      try {
        const response = await signupMutation(body).unwrap();
        if (response?.statusCode === 200) {
          setOtpSend(true);
        }
      } catch (error: any) {
        console.log("error: ", error);
        errorToast(error?.data?.message || "");
      }
    },
  });

  const handleResend = async () => {
    let body = {
      phone: arabicToEnglishNumbers(formik.values.phoneNumber),
      countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
    };

    try {
      const response = await signupMutation(body).unwrap();
      if (response?.statusCode === 200) {
        successToast(translation.toast_messages.otpResend);
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.message || "");
    }
  };

  const handleSubmit = async () => {
    if (otp === "") {
      setError(true);
      errorToast(translation.Auth.otp_verification.enterOtp);
      return;
    }

    if (otp?.length === 4) {
      setError(false);
      const body = {
        phone: arabicToEnglishNumbers(formik.values.phoneNumber),
        countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
        code: arabicToEnglishNumbers(otp),
        // deviceType: "WEB",
        // deviceToken: fcmToken || "1234",
      };

      try {
        const response = await otpVerification(body).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(
            STORAGE_KEYS.token,
            JSON.stringify(response?.data?.token)
          );
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );
          closeModal();
          dispatch(
            toggleAuthModal({
              isAuthModalVisible: true,
              ModalType: "profileSetup",
            })
          );
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    } else {
      errorToast(translation.Auth.otp_verification.enterOtp);
    }
  };

  return (
    <div>
      <Loader isLoad={isLoading || otpVerificationData?.isLoading} />
      <div className="AuthWrap" style={{ paddingTop: 0 }}>
        <div className={email ? "cross dbl" : "cross"}>
          {email ? (
            <KeyboardBackspaceIcon onClick={() => setEmail(false)} />
          ) : null}
          <CloseIcon
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          />
        </div>
        <div className="itemlogo">
          <div onClick={() => navigate("/")} className="logo">
            <figure>
              <img src="/static/images/logo_nw.png" alt="logo" />
            </figure>
          </div>
        </div>
        <h2></h2>
        <p />

        {!otpsend ? (
          <form onSubmit={formik.handleSubmit}>
            <div className="form_control">
              <div className="select_country">
                <p style={{ fontSize: "12px" }} className="select_p">
                  {translation.Auth.Sign_up.countryCode}
                </p>

                <Autocomplete
                  disabled={otpsend}
                  className="prfl_autocmplt"
                  disablePortal
                  id="combo-box-demo"
                  onChange={handleChangeVendorSelect}
                  options={countryCodes}
                  getOptionLabel={(option) => `${option?.code}`}
                  sx={{ width: "auto" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={translation.Auth.Sign_up.selectCountry}
                    />
                  )}
                  // defaultValue={defaultSelectedCountry}
                  defaultValue={countryCodes.find((option) =>
                    option.code.includes("(SA) +966")
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div className="contryCode-list">
                        <img src={option?.icon} alt="img" />
                        <span>{option?.code || ""}</span>
                      </div>
                    </li>
                  )}
                />

                <div className="horizontal_line" />

                <TextField
                  disabled={otpsend}
                  inputProps={{
                    maxLength: selectedCountry?.countryCode === "+91" ? 10 : 15,
                  }}
                  fullWidth
                  className="phoneField"
                  hiddenLabel
                  variant="outlined"
                  placeholder={translation.Globals.phone_number}
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={(val) => {
                    const allowedCharacters = /^[0-9\u0660-\u0669]*$/;
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (allowedCharacters.test(val.target.value)) {
                      formik.setFieldValue("phoneNumber", val.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
              <p className="err_msg">
                {formik.touched.phoneNumber && formik.errors.phoneNumber}
              </p>

              <div style={{ marginBottom: otpsend ? 10 : 50 }} />

              {/* {otpsend ? (
            <div>
              <p className="otp_txt">
                {translation.Auth.otp_verification.otp}
              </p>

              <div className="entr_otp">
                <FormControl className="opt_input" sx={{ width: "100%" }}>
                  <OTPInput
                    value={otp}
                    onChange={(value) => {
                      const allowedCharacters = /^[0-9\u0660-\u0669]*$/;
                      if (value === " " || value === ".") {
                      } else if (allowedCharacters.test(value)) {
                        setOtp(value);
                      }
                    }}
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={otpStyle}
                    inputType="text"
                    containerStyle={{
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  />
                  <br />
                </FormControl>
              </div>

              <div>
                {countDown === 0 ? (
                  <p className="resend">
                    {translation.Auth.otp_verification.dontGetOtp}{" "}
                    <span
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={handleResend}
                    >
                      {translation.Auth.otp_verification.resend}
                    </span>
                  </p>
                ) : (
                  <p className="counter">
                    {countDown < 10
                      ? `00: 0${countDown}`
                      : `00: ${countDown}`}
                  </p>
                )}
              </div>
            </div>
          ) : null} */}
            </div>

            <Button
              disabled={!formik.values.phoneNumber}
              value={
                !otpsend
                  ? translation.Auth.otp_verification.sendOtp
                  : translation.Globals.submit
              }
              onClick={() => {
                if (otpsend) {
                  handleSubmit();
                } else {
                  setError(true);
                }
              }}
            />
          </form>
        ) : (
          <div>
            <div className="form_control">
              <div className="select_country">
                <p style={{ fontSize: "12px" }} className="select_p">
                  {translation.Auth.Sign_up.countryCode}
                </p>

                <Autocomplete
                  disabled={otpsend}
                  className="prfl_autocmplt"
                  disablePortal
                  id="combo-box-demo"
                  onChange={handleChangeVendorSelect}
                  options={countryCodes}
                  getOptionLabel={(option) => `${option?.code}`}
                  sx={{ width: "auto" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={translation.Auth.Sign_up.selectCountry}
                    />
                  )}
                  // defaultValue={defaultSelectedCountry}
                  defaultValue={countryCodes.find((option) =>
                    option.code.includes("(SA) +966")
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div className="contryCode-list">
                        <img src={option?.icon} alt="img" />
                        <span>{option?.code || ""}</span>
                      </div>
                    </li>
                  )}
                />

                <div className="horizontal_line" />

                <TextField
                  disabled={otpsend}
                  inputProps={{
                    maxLength: selectedCountry?.countryCode === "+91" ? 10 : 15,
                  }}
                  fullWidth
                  className="phoneField"
                  hiddenLabel
                  variant="outlined"
                  placeholder={translation.Globals.phone_number}
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={(val) => {
                    const allowedCharacters = /^[0-9\u0660-\u0669]*$/;
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (allowedCharacters.test(val.target.value)) {
                      formik.setFieldValue("phoneNumber", val.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
              <p className="err_msg">
                {formik.touched.phoneNumber && formik.errors.phoneNumber}
              </p>

              <div style={{ marginBottom: otpsend ? 10 : 50 }} />
            </div>

            <div>
              <p className="otp_txt">{translation.Auth.otp_verification.otp}</p>

              <div className="entr_otp">
                <FormControl className="opt_input" sx={{ width: "100%" }}>
                  <OTPInput
                    value={otp}
                    onChange={(value) => {
                      const allowedCharacters = /^[0-9\u0660-\u0669]*$/;
                      if (value === " " || value === ".") {
                      } else if (allowedCharacters.test(value)) {
                        setOtp(value);
                      }
                    }}
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={otpStyle}
                    inputType="text"
                    containerStyle={{
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  />
                  <br />
                </FormControl>
              </div>

              <div>
                {countDown === 0 ? (
                  <p className="resend">
                    {translation.Auth.otp_verification.dontGetOtp}{" "}
                    <span
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={handleResend}
                    >
                      {translation.Auth.otp_verification.resend}
                    </span>
                  </p>
                ) : (
                  <p className="counter">
                    {countDown < 10 ? `00: 0${countDown}` : `00: ${countDown}`}
                  </p>
                )}
              </div>
            </div>
            <Button
              disabled={!formik.values.phoneNumber}
              value={
                !otpsend
                  ? translation.Auth.otp_verification.sendOtp
                  : translation.Globals.submit
              }
              onClick={() => {
                if (otpsend) {
                  handleSubmit();
                } else {
                  setError(true);
                }
              }}
            />
          </div>
        )}

        <div className="signUp">
          <h4>
            {translation.Auth.Sign_up.alreadyHaveAcc}{" "}
            <span
              onClick={() =>
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: true,
                    ModalType: "login",
                  })
                )
              }
            >
              {translation.header.login}
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
