import React, { Dispatch, SetStateAction, useState } from "react";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import "./Components.scss";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { letters, number } from "../utils/validation";
import useAuth from "../hooks/useAuth";
import { ListedPost } from "../types/General";
import {
  useAddRemoveLikeMutation,
  useChangeFavStatusMutation,
} from "../service/home";
import {
  errorToast,
  getFromStorage,
  STORAGE_KEYS,
  successToast,
} from "../helpers";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import useTranslation from "../hooks/Translation";

type props = {
  wishlist?: boolean;
  Auction?: boolean;
  posts?: boolean;
  category?: string;
  posttitle?: string;
  price?: number;
  carPlatenumber?: string;
  _id?: string;
  role?: string;
  activeCase?: number;
  favItems: any[];
  setFavItems: Dispatch<SetStateAction<any[]>>;
  likeItems: any[];
  setLikeItems: Dispatch<SetStateAction<any[]>>;
  item: any;
};

const CarPlateCard = ({
  wishlist,
  posts,
  category,
  _id,
  posttitle,
  price,
  carPlatenumber,
  role,
  activeCase,
  favItems,
  setFavItems,
  item,
  setLikeItems,
  likeItems,
  Auction,
}: props) => {
  const navigate = useNavigate();
  const language = getFromStorage(STORAGE_KEYS.language);
  const user = useAuth();
  const translation = useTranslation();

  const [changeFavStatus] = useChangeFavStatusMutation();
  const [changeLikeStatus] = useAddRemoveLikeMutation();

  const handleFav = async (e: any, item: ListedPost) => {
    e.stopPropagation();

    const data = {
      postId: _id,
    };

    try {
      const response = await changeFavStatus(data).unwrap();
      if (response?.statusCode === 200) {
        if (favItems?.includes(item)) {
          let indexToDelete = favItems.indexOf(item);
          favItems.splice(indexToDelete, 1);
          setFavItems([...favItems]);
          successToast(response?.message || "");
        } else {
          favItems?.push(item);
          setFavItems([...favItems]);
          successToast(response?.message || "");
        }
      } else {
        errorToast(translation.toast_messages.try_again);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLike = async (e: any, item: ListedPost) => {
    e.stopPropagation();

    const data = {
      postId: _id,
    };

    try {
      const response = await changeLikeStatus(data).unwrap();
      if (response?.statusCode === 200) {
        if (likeItems?.includes(item)) {
          let indexToDelete = likeItems.indexOf(item);
          likeItems.splice(indexToDelete, 1);
          setLikeItems([...likeItems]);
          successToast(response?.message || "");
        } else {
          likeItems?.push(item);
          setLikeItems([...likeItems]);
          successToast(response?.message || "");
        }
      } else {
        errorToast(translation.toast_messages.try_again);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="prdct_crd"
      onClick={() => {
        if (window.location.pathname === "/") {
          navigate(`/car-plate-detail/${_id}`, { state: "home" });
        } else {
          navigate(`/car-plate-detail/${_id}`, {
            state: role,
          });
        }
      }}
    >
      <div className="plate_main">
        <div className="plate_box">
          <ul className="pLft">
            <li className="pro_sans">
              {" "}
              {carPlatenumber
                ?.split("")
                /* @ts-ignore */
                ?.map((item: string) => number[`${item}`])
                ?.reverse()
                ?.join(" ")}
            </li>
            <li className="pro_sans">
              {posttitle
                ?.split("")
                /* @ts-ignore */
                ?.map((item: string) => letters[`${item?.toLowerCase()}`])
                ?.reverse()
                ?.join(" ")}
            </li>
            <li className="pro_sans">
              {" "}
              {carPlatenumber ? carPlatenumber.split("").join(" ") : ""}
            </li>
            <li className="pro_sans">
              {" "}
              {posttitle ? posttitle.split("").join(" ") : ""}
            </li>
          </ul>
          <div
            className="pRyt"
            style={{ background: category === "Pick-up Car" ? "#3c82c8" : "" }}
          >
            <figure>
              <img src="/static/images/lowhate_icon.png" alt="" />
            </figure>
            <p>السعودية</p>
            <div className="ksa">
              <h4>K</h4>
              <h4>S</h4>
              <h4>A</h4>
            </div>
            <div className="greenDot" />
          </div>
        </div>
      </div>
      <div className="card_des">
        <div className="like">
          {wishlist || posts ? (
            <div className="h_price">
              <h4>
                {language === "en"
                  ? posttitle || ""
                  : posttitle
                      ?.split("")
                      /* @ts-ignore */
                      ?.map((item: string) => letters[`${item?.toLowerCase()}`])
                      ?.reverse()
                      ?.join(" ") || ""}{" "}
                <span className="">
                  {" "}
                  {carPlatenumber ? carPlatenumber : ""}
                </span>
              </h4>
              <p className="public">({category || ""})</p>
            </div>
          ) : (
            <h4>
              {posttitle || ""}
              <span className=""> {carPlatenumber ? carPlatenumber : ""}</span>
            </h4>
          )}

          {!wishlist && !posts && user ? (
            <div className="fvrt_img">
              {favItems?.length && favItems?.includes(item) ? (
                <FavoriteIcon
                  onClick={(e) => {
                    handleFav(e, item);
                  }}
                />
              ) : (
                <FavoriteBorderRoundedIcon
                  onClick={(e) => {
                    handleFav(e, item);
                  }}
                />
              )}
            </div>
          ) : null}
        </div>
        {/* {!wishlist && !posts ? <h3>{translation.posts.startingFrom}</h3> : ""} */}
        {!wishlist && !posts && Auction ? (
          <h3>{translation.posts.startingFrom}</h3>
        ) : (
          ""
        )}

        {wishlist || posts ? (
          <div className="plate_price">
            <p>{translation.Globals.price} :</p>
            <h2 style={{ color: role === "OFFER" ? "#24647e" : "" }}>
              <span className="">
                {price ? Number(price).toFixed(2) : "0.00"}
              </span>{" "}
              {translation.Globals.sar}
            </h2>
          </div>
        ) : (
          <h2 style={{ color: role === "OFFER" ? "#24647e" : "" }}>
            <span className="">
              {price ? Number(price).toFixed(2) : "0.00"}
            </span>{" "}
            {translation.Globals.sar}
          </h2>
        )}

        {wishlist ? (
          <div className="like1">
            {favItems?.includes(item) ? (
              <FavoriteIcon onClick={(e) => handleFav(e, item)} />
            ) : (
              <FavoriteBorderRoundedIcon onClick={(e) => handleFav(e, item)} />
            )}
          </div>
        ) : posts ? (
          <div className="auction">
            {role === "AUCTION" ? (
              <>
                <p>{translation.Globals.auction} :</p>
                <div className="enable">
                  <p>{translation.Globals.enable}</p>
                </div>
              </>
            ) : null}
          </div>
        ) : (
          <>
            {user ? (
              <div className="btns">
                {likeItems?.includes(item) ? (
                  <figure
                    onClick={(e) => {
                      handleLike(e, item);
                    }}
                  >
                    <img src="/static/images/liked_btn.png" alt="img" />
                  </figure>
                ) : (
                  <figure
                    onClick={(e) => {
                      handleLike(e, item);
                    }}
                  >
                    <img src="/static/images/like_btn.png" alt="img" />
                  </figure>
                )}

                <figure
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/comments/${_id}`, { state: item });
                  }}
                >
                  <img src="/static/images/comment_btn.png" alt="img" />
                </figure>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default CarPlateCard;
