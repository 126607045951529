import moment from "moment";
import React, { Dispatch, SetStateAction } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { RWebShare } from "react-web-share";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { ListedPost } from "../types/General";
import { useChangeFavStatusMutation } from "../service/home";
import { errorToast, getFromStorage, STORAGE_KEYS } from "../helpers";
import useTranslation from "../hooks/Translation";
import { letters } from "../utils/validation";

type props = {
  posttitle?: string;
  price?: number;
  offerPrice?: number | undefined;
  carPlatenumber?: string;
  _id?: string;
  createdAt?: string;
  typeOfPrice?: string;
  state?: string;
  role?: string;
  setOpenWarn: Dispatch<SetStateAction<boolean>>;
  setSelectedId: Dispatch<SetStateAction<string>>;
  postCreatedBy?: string;
  listedPost?: ListedPost | null;
  handleFav: (e: any, item: string) => void;
  path?: string;
};

const PlateDetailCard = ({
  _id,
  posttitle,
  price,
  carPlatenumber,
  createdAt,
  typeOfPrice,
  role,
  state,
  setOpenWarn,
  setSelectedId,
  postCreatedBy,
  listedPost,
  handleFav,
  offerPrice,
  path,
}: props) => {
  const navigate = useNavigate();
  const language = getFromStorage(STORAGE_KEYS.language);
  const user = useAuth();
  const translation = useTranslation();

  return (
    <div className="rightCard">
      <div className="auto_ryt">
        {/* <h3>POST ID: 216411722</h3> */}
        <h2>
          {language === "en"
            ? posttitle
            : posttitle
                ?.split("")
                /* @ts-ignore */
                ?.map((item: string) => letters[`${item?.toLowerCase()}`])
                ?.reverse()
                ?.join(" ") || ""}{" "}
          <span className=""> {carPlatenumber || ""}</span>
        </h2>
        <p className="p2">
          {translation.posts.postedOn}:{" "}
          {moment(createdAt).format("MMM Do, YYYY")}
        </p>
        <div className="starting">
          <p className="p2">{translation.posts.startedFrom}</p>
          <h2>
            {role === "OFFER" ? null : (
              <>
                {typeOfPrice === "FixedAndNegotiate"
                  ? translation.Globals.fixedNego
                  : typeOfPrice === "Fixed"
                  ? translation.Globals.fixedPrice
                  : translation.Globals.negotiate}
              </>
            )}
            <span className=""> {offerPrice ? offerPrice : price || "0"}</span>{" "}
            {translation.Globals.sar}
          </h2>
        </div>
      </div>
      {state === "myOfferPost" || path === "comment" ? null : (
        <div className="auto_left">
          {user?._id === postCreatedBy ? (
            <div className="fig">
              <figure
                onClick={() =>
                  role === "OFFER"
                    ? navigate(`/post-for-offer/${_id}`)
                    : role === "SELL"
                    ? navigate(`/post-for-sell/${_id}`)
                    : navigate(`/post-for-auction/${_id}`)
                }
              >
                <EditIcon />
              </figure>
              {/* <figure
                onClick={() => {
                  if (_id) {
                    setOpenWarn(true);
                    setSelectedId(_id);
                  }
                }}
              >
                <DeleteIcon />
              </figure> */}
            </div>
          ) : user?._id !== postCreatedBy ? (
            <div className="fig">
              <RWebShare
                data={{
                  text: "Car Plate",
                  url: `https://lowhate.appgrowthcompany.com/car-plate-detail/${_id}`,
                  title: "Share",
                }}
                onClick={() => console.log("Shared successfully!")}
              >
                <figure>
                  <img src="/static/images/share.svg" alt="Product" />
                </figure>
              </RWebShare>
              {user ? (
                <>
                  {listedPost?.isWishlistByMe ? (
                    <figure onClick={(e) => handleFav(e, listedPost?._id)}>
                      <img src="/static/images/filled_heart.svg" alt="" />
                    </figure>
                  ) : (
                    <figure
                      onClick={(e) => handleFav(e, String(listedPost?._id))}
                    >
                      <img src="/static/images/heart.svg" alt="" />
                    </figure>
                  )}
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default PlateDetailCard;
