import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./search.scss";
import { ChoosePlate } from "../../features";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { Category } from "../../types/General";
import { errorToast } from "../../helpers";
import { useLazyGetAllCateQuery } from "../../service/posts";
import useTranslation from "../../hooks/Translation";

const Search = () => {
  const translation = useTranslation();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState<string[]>(["", "", ""]);
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [category, setCategory] = useState<Category[]>([]);

  const [postType, setPostType] = useState(true);
  const [selectedId, setSelectedId] = useState("");
  const [allCategory] = useLazyGetAllCateQuery();
  const validChars = /[aAbBjJdDrRsSxXtTeEgGkKlLzZnNhHuUvV'ابحدرسصطعقكلمنهـوى]/;

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && inputs[index] === "") {
      // Move to the previous input if backspace is pressed on an empty input
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        (prevInput as HTMLInputElement).focus();
      }
    }
  };

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = event.target;

    // Convert value to uppercase for English characters only
    if (/^[a-z]$/i.test(value)) {
      value = value.toUpperCase();
    }

    // Check if the input is a valid character
    if (value === "" || validChars.test(value)) {
      const newInputs = [...inputs];
      newInputs[index] = value;

      setInputs(newInputs);
      console.log(newInputs, "inputs");

      // Move focus to the next input if the current input is not empty and not the last input
      if (value !== "" && index < inputs.length - 1) {
        setTimeout(() => {
          const nextInput = document.getElementById(
            `input-${index + 1}`
          ) as HTMLInputElement | null;
          if (nextInput) nextInput.focus();
        }, 0); // Timeout ensures DOM is updated before focusing
      }
    } else {
      console.log("Invalid character entered");
    }
  };

  const handleChangeOTP = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = event.target;

    // Check if the value is a valid numeric character (0-9)
    if (/\d/.test(value)) {
      value = value.toUpperCase();

      if (value !== "" || validChars.test(value)) {
        const newInputs = [...otp];
        newInputs[index] = value;
        setOtp(newInputs);

        // Move focus to the next input if the current input is not empty and not the last input
        if (value !== "" && index < otp.length - 1) {
          setTimeout(() => {
            const nextInput = document.getElementById(
              `otpinput-${index + 1}`
            ) as HTMLInputElement | null;
            if (nextInput) nextInput.focus();
          }, 0); // Timeout ensures DOM is updated before focusing
        }
      }
    } else {
      console.log("Invalid character entered. Only numbers are allowed.");
    }
  };

  const handleKeyDownOTP = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace") {
      const newInputs = [...otp];

      // If the current input is empty and we're not at the first input, move focus to the previous field
      if (otp[index] === "") {
        if (index > 0) {
          // Focus on the previous input and clear it
          const prevInput = document.getElementById(
            `otpinput-${index - 1}`
          ) as HTMLInputElement | null;
          if (prevInput) {
            // Focus the previous input and immediately clear it
            prevInput.focus();
            prevInput.setSelectionRange(0, 0); // Ensure the cursor is at the start of the input

            // Clear the previous input
            newInputs[index - 1] = "";
          }
        }
      } else {
        // Clear the current input immediately
        newInputs[index] = "";
      }

      setOtp(newInputs);
    }
  };
  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data);
        setSelectedId(response?.data?.[0]?._id || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleSearch = () => {
    navigate("/review", {
      state: {
        category: selectedId,
        posttitle: inputs,
        carPlatenumber: otp,
      },
    });
  };

  useEffect(() => {
    getAllCate();
  }, []);

  return (
    <Layout>
      <div className="wrapCls">
        <div className="search_card">
          <div className="container">
            <div className="plate_select">
              <ChoosePlate
                category={category}
                setPostType={setPostType}
                postType={postType}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
              />
            </div>
            <div>
              <p>{translation.home.searchText}</p>
              <div className="plate_board">
                {inputs.map((value, index) => (
                  <input
                    className="plate_no"
                    key={index}
                    id={`input-${index}`}
                    maxLength={1}
                    value={value}
                    type="text"
                    disabled={index > 0 && !inputs[index - 1]} // Disable input if it's not the first and the previous input is empty
                    onFocus={(event) =>
                      index > 0 && !inputs[index - 1] && event.target.blur()
                    } // Prevent focus if disabled
                    onChange={(event) => handleChange(index, event)}
                    onKeyDown={(event) => handleKeyDown(index, event)}
                  />
                ))}
              </div>
              <p>{translation.home.seearchText1}</p>
              <div className="plate_board board2">
                {otp.map((value, index) => (
                  <input
                    className="plate_no"
                    key={index}
                    id={`otpinput-${index}`}
                    maxLength={1}
                    value={value}
                    type="text"
                    disabled={index > 0 && !otp[index - 1]} // Disabled if previous input is empty
                    onFocus={(event) =>
                      index > 0 && !otp[index - 1] && event.target.blur()
                    } // Prevent focus if disabled
                    onChange={(event) => handleChangeOTP(index, event)}
                    onKeyDown={(event) => handleKeyDownOTP(index, event)}
                  />
                ))}
              </div>
              <div className="search_btn">
                <Button
                  value={translation.header.search}
                  onClick={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
