import React, { useEffect, useState } from "react";
import { CarPlateCard } from "../../components";
import cardData from "../../data/ProductData.json";
import { useNavigate } from "react-router-dom";
import { Dashboard } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import { getFromStorage, STORAGE_KEYS } from "../../helpers";

type props = {
  dashboardData: Dashboard | null;
  loading: boolean;
};

const OfferPost = ({ dashboardData, loading }: props) => {
  const language = getFromStorage(STORAGE_KEYS.language);

  const translation = useTranslation();
  const navigate = useNavigate();
  const [favItems, setFavItems] = useState<any[]>([]);
  const [likeItems, setLikeItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const binaryData = dashboardData?.offerPosts || [];
    const fav_data = binaryData
      .filter((item: any) => item?.isWishlistByMe)
      .map((item: any) => item);
    const like_data = binaryData
      .filter((item: any) => item?.isLikedByMe)
      .map((item: any) => item);
    setLikeItems(like_data);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [dashboardData?.offerPosts]);

  return (
    <section className="prodcts_sc container ">
      <div className="prdct_hdngs pt">
        <h2>{translation?.home.offerPost}</h2>
        {!loading && !dashboardData?.offerPosts?.length ? (
          <div className="noData">
            <figure>
              <img src="/static/images/noData.webp" alt="" />
            </figure>
            <h3> {translation?.error.noData}</h3>
          </div>
        ) : dashboardData?.offerPosts?.length &&
          dashboardData?.offerPosts?.length > 5 ? (
          <p
            onClick={() =>
              navigate("/see-all", {
                state: {
                  key: translation.home.offerPost,
                  type: "offerPosts",
                },
              })
            }
          >
            {translation?.home.seeAll}
          </p>
        ) : null}
      </div>
      <div className="crds_sec">
        {dashboardData?.offerPosts?.length
          ? dashboardData?.offerPosts
              ?.slice(0, 5)
              .map((item) => (
                <CarPlateCard
                  category={
                    language === "en"
                      ? item?.categoryData?.categoryName || ""
                      : item?.categoryData?.categoryName_ar || ""
                  }
                  posttitle={item?.posttitle}
                  price={item?.offerPrice}
                  carPlatenumber={item?.carPlatenumber}
                  _id={item?._id}
                  setFavItems={setFavItems}
                  favItems={favItems}
                  item={item}
                  likeItems={likeItems}
                  setLikeItems={setLikeItems}
                  role={item?.role}
                />
              ))
          : null}
      </div>
    </section>
  );
};

export default OfferPost;
