import React, { useEffect, useState } from "react";
import { BookingCard, CarPlateCard, InputField } from "../../components";
import { ListedPost } from "../../types/General";
import { useLazyGetWishlistQuery } from "../../service/posts";
import { getFromStorage, Loader, STORAGE_KEYS } from "../../helpers";
import useTranslation from "../../hooks/Translation";

const MyWishlist = () => {
  const translation = useTranslation();
  const language = getFromStorage(STORAGE_KEYS.language);

  const [listedPost, { isLoading }] = useLazyGetWishlistQuery();
  const [wishlist, setWishlist] = useState<ListedPost[]>([]);
  const [favItems, setFavItems] = useState<any[]>([]);
  const [likeItems, setLikeItems] = useState<any[]>([]);

  const getWishlistPost = async () => {
    try {
      const response = await listedPost({}).unwrap();

      if (response?.statusCode === 200) {
        setWishlist(response?.data?.post || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkFavProperties = () => {
    const fav_data = wishlist
      .filter((item: any) => item?.isWishlistByMe)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [wishlist]);

  useEffect(() => {
    getWishlistPost();
  }, []);

  return (
    <div className="main">
      <Loader isLoad={isLoading} />
      <div className={favItems?.length ? "crds_sec" : "crds_sec_noData"}>
        {favItems?.length ? (
          favItems?.map((item) => (
            <CarPlateCard
              role={item?.role}
              wishlist
              posts
              category={
                language === "en"
                  ? item?.categoryData?.categoryName || ""
                  : item?.categoryData?.categoryName_ar || ""
              }
              posttitle={item?.posttitle}
              price={item?.price}
              carPlatenumber={item?.carPlatenumber}
              _id={item?._id}
              setFavItems={setFavItems}
              favItems={favItems}
              item={item}
              likeItems={likeItems}
              setLikeItems={setLikeItems}
            />
          ))
        ) : (
          <div className="noData">
            <figure>
              <img src="/static/images/noData.webp" alt="" />
            </figure>
            <h3> {translation.error.noData}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyWishlist;
