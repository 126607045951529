import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";

import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useLazyGetProfileQuery,
  useOtpVerficationMutation,
  usePostLoginMutation,
} from "../../service/Auth";
import "../auth/Auth.scss";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  successToast,
} from "../../helpers";
import { setCredentials, userLoginRes } from "../../reducers/authSlice";
import useTranslation from "../../hooks/Translation";
import { arabicToEnglishNumbers } from "../../utils/validation";
import OTPInput from "react-otp-input";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
  setSignUp: Dispatch<SetStateAction<boolean>>;
};

type CountryType = {
  code: string;
  icon: string;
  id: string;
  countryCode: string;
};

const otpStyle = {
  width: "16%",
  height: "60px",
  "box-sizing": "border-box",
  border: "1px solid #1d1d1d4f",
  padding: "10px",
};

const Login = ({ closeModal, setPhone, setSignUp }: props) => {
  const translation = useTranslation();
  const fcmToken = getFromStorage(STORAGE_KEYS.fcmToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loginMutation, { isLoading }] = usePostLoginMutation();
  const [getProfile, GetProfileData] = useLazyGetProfileQuery();
  const [phoneView, setPhoneView] = useState<boolean>(false);
  const [otpsend, setOtpSend] = useState(false);
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState(false);
  const [otpVerification, otpVerificationData] = useOtpVerficationMutation();

  const [selectedCountry, setSelectedCountry] = useState<CountryType>({
    code: "(SA) +966",
    icon: "/static/images/su_flag.webp",
    id: "2",
    countryCode: "+966",
  });

  const countryCodes = [
    {
      code: "(IND) +91",
      icon: "/static/images/in_flag.png",
      id: "1",
      countryCode: "+91",
    },
    {
      code: "(SA) +966",
      icon: "/static/images/su_flag.webp",
      id: "2",
      countryCode: "+966",
    },
  ];

  const handleForgot = () => {
    closeModal();
    dispatch(
      toggleAuthModal({ isAuthModalVisible: true, ModalType: "forgot" })
    );
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
      remember: false,
      phoneNumber: "",
      countryCode: "",
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      userName: Yup.string().when("phoneView", {
        is: (value: boolean) => value === false,
        then: (schema) =>
          schema
            .required(translation.validations.required_field)
            .min(2, translation.validations.min_two)
            .max(80, translation.validations.max_Eighty),
        otherwise: (schema) => schema.notRequired(),
      }),

      password: phoneView
        ? Yup.string().notRequired()
        : Yup.string()
            .required(translation.validations.required_field)
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
              translation.validations.password_validation
            ),
      phoneNumber: Yup.string().when("phoneView", {
        is: (value: boolean) => value === true,
        then: (schema) =>
          schema
            .required(translation.validations.required_field)
            .min(9, translation.validations.min_nine)
            .max(15, translation.validations.max_fifteen),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: async (values: any) => {
      formik.setSubmitting(true);
      let body;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(formik.values.userName)) {
        body = {
          email: formik.values.userName,
          password: formik.values.password,
          deviceType: "WEB",
          deviceToken: fcmToken || null,
        };
      } else if (phoneView === true) {
        body = {
          phone: arabicToEnglishNumbers(formik.values.phoneNumber),
          // password: formik.values.password,
          // deviceType: "WEB",
          // deviceToken: fcmToken || null,
          countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
        };
      } else {
        body = {
          userName: formik.values.userName,
          password: formik.values.password,
          deviceType: "WEB",
          deviceToken: fcmToken || null,
        };
      }
      console.log(body, "body of login");

      try {
        const response = await loginMutation(body).unwrap();
        if (response?.statusCode === 200) {
          if (phoneView === true) {
            setOtpSend(true);
          } else {
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token)
            );
            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(formik.values.userName)
            );
            dispatch(
              setCredentials({
                user: response?.data || null,
                token: response?.data?.token || "",
              })
            );
            dispatch(
              userLoginRes({
                userLogin: true,
              })
            );
            getUserDetails();
            if (formik.values.remember) {
              setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
            } else {
              removeFromStorage(STORAGE_KEYS.credentials);
            }
            closeModal();
            navigate("/");
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  const handleChangeVendorSelect = (event: any, newValue: any) => {
    setSelectedCountry(newValue);
    formik.setFieldValue("userName", "");
  };

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("userName", rememberData?.userName);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const handleResend = async () => {
    let body = {
      phone: arabicToEnglishNumbers(formik.values.phoneNumber),
      countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
    };

    try {
      const response = await loginMutation(body).unwrap();
      if (response?.statusCode === 200) {
        successToast(translation.toast_messages.otpResend);
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.message || "");
    }
  };

  const handleSubmit = async () => {
    if (otp === "") {
      setError(true);
      errorToast(translation.Auth.otp_verification.enterOtp);
      return;
    }

    if (otp?.length === 4) {
      setError(false);
      const body = {
        phone: arabicToEnglishNumbers(formik.values.phoneNumber),
        countryCode: selectedCountry ? selectedCountry?.countryCode : "+966",
        code: arabicToEnglishNumbers(otp),
      };

      try {
        const response = await otpVerification(body).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(
            STORAGE_KEYS.token,
            JSON.stringify(response?.data?.token)
          );
          setToStorage(
            STORAGE_KEYS.userData,
            JSON.stringify(formik.values.userName)
          );
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: response?.data?.token || "",
            })
          );
          dispatch(
            userLoginRes({
              userLogin: true,
            })
          );
          getUserDetails();
          closeModal();
          navigate("/");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    } else {
      errorToast(translation.Auth.otp_verification.enterOtp);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);
  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="AuthWrap">
        <div className="cross">
          <CloseIcon
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          />
        </div>
        <h2>{translation.Auth.Login.logintoLowhate}</h2>
        <p />

        {!otpsend ? (
          <form onSubmit={formik.handleSubmit}>
            {phoneView ? (
              <div className="form_control">
                <div
                  className="select_country"
                  style={{ marginBottom: "20px" }}
                >
                  <p style={{ fontSize: "12px" }} className="select_p">
                    {translation.Auth.Sign_up.countryCode}
                  </p>

                  <Autocomplete
                    className="prfl_autocmplt"
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleChangeVendorSelect}
                    options={countryCodes}
                    getOptionLabel={(option) => `${option?.code}`}
                    sx={{ width: "auto" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={translation.Auth.Sign_up.selectCountry}
                      />
                    )}
                    defaultValue={countryCodes.find((option) =>
                      option.code.includes("(SA) +966")
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div className="contryCode-list">
                          <img src={option?.icon} alt="img" />
                          <span>{option?.code || ""}</span>
                        </div>
                      </li>
                    )}
                  />

                  <div className="horizontal_line" />

                  <TextField
                    inputProps={{
                      maxLength:
                        selectedCountry?.countryCode === "+91" ? 10 : 15,
                    }}
                    fullWidth
                    className="phoneField"
                    hiddenLabel
                    variant="outlined"
                    placeholder={translation.Globals.phone_number}
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={(val) => {
                      const allowedCharacters = /^[0-9\u0660-\u0669]*$/;
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (allowedCharacters.test(val.target.value)) {
                        formik.setFieldValue("phoneNumber", val.target.value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </div>
              </div>
            ) : (
              <InputField
                placeholder={
                  translation.Globals.userName +
                  " / " +
                  translation.Globals.email
                }
                name="userName"
                id="userName"
                value={formik.values.userName}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.userName && formik.errors.userName}
              />
            )}
            {phoneView ? null : (
              <>
                <InputField
                  placeholder={translation.Auth.Login.password}
                  password
                  name="password"
                  id="password"
                  value={formik.values.password}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <div className="forgotBtn" style={{ textAlign: "center" }}>
                  <h4 onClick={() => setPhoneView(!phoneView)}>
                    {phoneView
                      ? translation.Auth.Login.sign_up_with_email
                      : translation.Auth.Login.sign_up_with_phone}
                  </h4>
                </div>
                <div className="remmerbar">
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={formik.values.remember}
                    name="remember"
                    onChange={formik.handleChange}
                    label={translation.Auth.Login.remember}
                  />

                  <div className="anchor_link">
                    <h3 onClick={handleForgot}>
                      {translation.Auth.Login.forgot_pas}
                    </h3>
                  </div>
                </div>
              </>
            )}

            <Button value={translation.Globals.Login} />
          </form>
        ) : (
          <div>
            <div className="form_control">
              <div className="select_country">
                <p style={{ fontSize: "12px" }} className="select_p">
                  {translation.Auth.Sign_up.countryCode}
                </p>

                <Autocomplete
                  disabled={otpsend}
                  className="prfl_autocmplt"
                  disablePortal
                  id="combo-box-demo"
                  onChange={handleChangeVendorSelect}
                  options={countryCodes}
                  getOptionLabel={(option) => `${option?.code}`}
                  sx={{ width: "auto" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={translation.Auth.Sign_up.selectCountry}
                    />
                  )}
                  // defaultValue={defaultSelectedCountry}
                  defaultValue={countryCodes.find((option) =>
                    option.code.includes("(SA) +966")
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div className="contryCode-list">
                        <img src={option?.icon} alt="img" />
                        <span>{option?.code || ""}</span>
                      </div>
                    </li>
                  )}
                />

                <div className="horizontal_line" />

                <TextField
                  disabled={otpsend}
                  inputProps={{
                    maxLength: selectedCountry?.countryCode === "+91" ? 10 : 15,
                  }}
                  fullWidth
                  className="phoneField"
                  hiddenLabel
                  variant="outlined"
                  placeholder={translation.Globals.phone_number}
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={(val) => {
                    const allowedCharacters = /^[0-9\u0660-\u0669]*$/;
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (allowedCharacters.test(val.target.value)) {
                      formik.setFieldValue("phoneNumber", val.target.value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
              <p className="err_msg">
                {formik.touched.phoneNumber && formik.errors.phoneNumber}
              </p>

              <div style={{ marginBottom: otpsend ? 10 : 50 }} />
            </div>

            <div>
              <p className="otp_txt">{translation.Auth.otp_verification.otp}</p>

              <div className="entr_otp">
                <FormControl className="opt_input" sx={{ width: "100%" }}>
                  <OTPInput
                    value={otp}
                    onChange={(value) => {
                      const allowedCharacters = /^[0-9\u0660-\u0669]*$/;
                      if (value === " " || value === ".") {
                      } else if (allowedCharacters.test(value)) {
                        setOtp(value);
                      }
                    }}
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={otpStyle}
                    inputType="text"
                    containerStyle={{
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  />
                  <br />
                </FormControl>
              </div>

              <div>
                {countDown === 0 ? (
                  <p className="resend">
                    {translation.Auth.otp_verification.dontGetOtp}{" "}
                    <span
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={handleResend}
                    >
                      {translation.Auth.otp_verification.resend}
                    </span>
                  </p>
                ) : (
                  <p className="counter">
                    {countDown < 10 ? `00: 0${countDown}` : `00: ${countDown}`}
                  </p>
                )}
              </div>
            </div>
            <Button
              disabled={!formik.values.phoneNumber}
              value={
                !otpsend
                  ? translation.Auth.otp_verification.sendOtp
                  : translation.Globals.submit
              }
              onClick={() => {
                if (otpsend) {
                  handleSubmit();
                } else {
                  setError(true);
                }
              }}
            />
          </div>
        )}

        <div className="mrgn_Btm" style={{ marginBottom: 80 }} />
        <div className="signUp">
          <h4>
            {translation.Auth.Login.not_member}
            {"? "}
            <span
              onClick={() => {
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: true,
                    ModalType: "signUp",
                  })
                );
                setSignUp(true);
              }}
            >
              {translation.Auth.Login.signup}
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Login;
