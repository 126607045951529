import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./details.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import {
  Button,
  CarNumberPlate,
  PayNowModal,
  PlateDetailCard,
  WarnModal,
} from "../../components";
import {
  useAdvertisePostMutation,
  useLazyClosePostQuery,
  useLazyDeleteAuctionPostByIdQuery,
  useLazyDeleteSellPostByIdQuery,
  useLazyGetAuctionPostDetailByIdQuery,
  useLazyGetPostDetailByIdQuery,
  useLazyGetPostServiceQuery,
} from "../../service/posts";
import { ListedPost, PostService } from "../../types/General";
import { errorToast, successToast } from "../../helpers";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import {
  useAddCommentOnPostMutation,
  useChangeFavStatusMutation,
  useLazyGetPostByIdQuery,
} from "../../service/home";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Modal,
  TextField,
} from "@mui/material";
import { usePayTokenMoneyMutation } from "../../service/bidding";
import { toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch } from "../../hooks/store";
import useTranslation from "../../hooks/Translation";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";

const ServiceStatus = {
  0: " Under Review",
  1: " Approved",
  2: " Approved",
  3: " Auction Live",
  4: " Rejected",
  5: " Cancelled",
  6: " Completed",
} as any;

const PlateDetail = () => {
  const dispatch = useAppDispatch();
  const user = useAuth();
  const location = useLocation();
  const { state } = location;
  const translation = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();

  const [deletePostById] = useLazyDeleteSellPostByIdQuery();
  const [getPostDetailById] = useLazyGetPostDetailByIdQuery();
  const [getHomePostByIdMutation] = useLazyGetPostByIdQuery();
  const [auctionById] = useLazyGetAuctionPostDetailByIdQuery();
  const [deleteAuctionById] = useLazyDeleteAuctionPostByIdQuery();
  const [changeFavStatus] = useChangeFavStatusMutation();
  const [advertisePost] = useAdvertisePostMutation();
  const [closePost] = useLazyClosePostQuery();
  const [addComment, { isLoading }] = useAddCommentOnPostMutation();
  const [tokenMutation, tokenMutationData] = usePayTokenMoneyMutation();
  const style = generateResponsiveStyle();

  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState<string>("");
  const [openWarn, setOpenWarn] = useState(false);
  const [startBid, setStartBid] = useState(false);
  const [sold, setSold] = useState(false);
  const [countDown, setCountDown] = useState<number>(20);
  console.log("countDown: ", countDown);
  const [listedPost, setListedPost] = useState<ListedPost | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");
  const [tokenPrice, setTokenPrice] = useState<number>(0);
  const [subReply, setSubReply] = useState<string>("");

  const payTokenMoney = async () => {
    let data = {
      postId: listedPost?._id,
      tokenPrice: tokenPrice,
    };

    try {
      const response = await tokenMutation(data).unwrap();
      if (response?.statusCode === 200) {
        setOpen(false);
        navigate(`/bid-detail/${listedPost?._id}`);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error, "errorrr");
    }
  };

  //comment
  const handleClickOpen = () => {
    setOpenComment(true);
  };

  const handleClose = () => {
    setOpenComment(false);
  };

  const handleDeleteProduct = async (id: string) => {
    try {
      let res;
      if (state === "myAuctionPost") {
        res = await deleteAuctionById({ id }).unwrap();
      } else {
        res = await deletePostById({ id }).unwrap();
      }

      if (res?.statusCode === 200) {
        successToast(translation.toast_messages.postdeleted);
        navigate("/profile", {
          state: state === "myAuctionPost" ? "auctionPost" : "sellPost",
        });
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleStartBid = (isTokenMoneyPaid: boolean) => {
    if (!isTokenMoneyPaid) {
      setOpen(true);
    } else {
      navigate(`/bid-detail/${listedPost?._id}`);
    }
  };

  //handleFav
  const handleFav = async (e: any, id: string) => {
    e.stopPropagation();

    const data = {
      postId: id,
    };

    try {
      const response = await changeFavStatus(data).unwrap();
      if (response?.statusCode === 200) {
        if (id && state !== "home" && state !== "myAuctionPost") {
          getPlateDetailById(id);
        } else if (id && state === "home") {
          getHomePostById(id);
        } else if (id && state === "myAuctionPost") {
          getAuctionDetailById(id);
        }
      } else {
        errorToast("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubComment = async (
    id: string | undefined,
    comment: string,
    parentId: string
  ) => {
    const body = {
      postId: id,
      comment,
      parentId: parentId,
    };

    if (id) {
      try {
        const response = await addComment(body).unwrap();
        if (response?.statusCode === 200) {
          setSubReply("");
          if (id && state !== "home" && state !== "myAuctionPost") {
            getPlateDetailById(id);
          } else if (id && state === "home") {
            getHomePostById(id);
          } else if (id && state === "myAuctionPost") {
            getAuctionDetailById(id);
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const handleClosePost = async (id: string) => {
    try {
      const response = await closePost({ postId: id }).unwrap();
      if (response?.statusCode === 200) {
        setOpenClose(false);
        successToast(translation.toast_messages.removePost);
        if (id && state !== "home" && state !== "myAuctionPost") {
          getPlateDetailById(id);
        } else if (id && state === "home") {
          getHomePostById(id);
        } else if (id && state === "myAuctionPost") {
          getAuctionDetailById(id);
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleAdvertisePost = async () => {
    if (listedPost?._id) {
      const body = {
        postId: listedPost?._id,
      };
      try {
        const response = await advertisePost(body).unwrap();
        if (response?.statusCode === 200) {
          successToast(translation.toast_messages.thankyouMsg);

          if (id && state !== "home" && state !== "myAuctionPost") {
            getPlateDetailById(id);
          } else if (id && state === "home") {
            getHomePostById(id);
          } else if (id && state === "myAuctionPost") {
            getAuctionDetailById(id);
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const getPlateDetailById = async (id: string) => {
    try {
      const response = await getPostDetailById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setListedPost(response?.data);
        const { price, reservePrice } = response?.data;
        const actualPrice = price * (reservePrice / 100);
        setTokenPrice(Number(actualPrice.toFixed(1)));
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getAuctionDetailById = async (id: string) => {
    try {
      const response = await auctionById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setListedPost(response?.data);
        const { price, reservePrice } = response?.data;
        const actualPrice = price * (reservePrice / 100);
        setTokenPrice(Number(actualPrice.toFixed(1)));
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getHomePostById = async (id: string) => {
    try {
      const response = await getHomePostByIdMutation({
        id,
        userId: user ? user?._id : "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setListedPost(response?.data);
        const { price, reservePrice } = response?.data;
        const actualPrice = price * (reservePrice / 100);
        setTokenPrice(Number(actualPrice.toFixed(1)));
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && state === "AUCTION") {
      getAuctionDetailById(id);
    } else if ((id && state === "SELL") || (id && state === "OFFER")) {
      getPlateDetailById(id);
    } else if (id) {
      getHomePostById(id);
    }
  }, []);

  const isTimeBetween = () => {
    if (listedPost) {
      const currentTime = moment();
      const startTime = moment(listedPost.startTime, "HH:mm");
      const endTime = moment(listedPost.endTime, "HH:mm");

      return currentTime.isBetween(startTime, endTime, null, "[]");
    }
  };

  useEffect(() => {
    if (sold) {
      if (countDown > 0) {
        setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
      } else {
        setCountDown(0);
      }
    }
  }, [countDown, sold]);

  useEffect(() => {
    if (countDown === 0 && id) {
      handleClosePost(id);
    }
  }, [countDown, id]);

  return (
    <Layout>
      <div className="wrapCls">
        <div className="comment_card">
          <div className="container">
            <h1 className="prdct_dtl_hdng">
              {translation.posts.carPlateDetail}
            </h1>
            <div className="detail_main">
              <div className="dLeft">
                <CarNumberPlate
                  category={listedPost?.category}
                  posttitle={listedPost?.posttitle}
                  carPlatenumber={listedPost?.carPlatenumber}
                />
              </div>
              <div className="dRyt">
                <PlateDetailCard
                  state={state}
                  posttitle={listedPost?.posttitle}
                  price={listedPost?.price}
                  offerPrice={listedPost?.offerPrice}
                  carPlatenumber={listedPost?.carPlatenumber}
                  _id={listedPost?._id}
                  role={listedPost?.role}
                  postCreatedBy={listedPost?.userId}
                  createdAt={listedPost?.createdAt}
                  typeOfPrice={listedPost?.typeOfPrice}
                  setSelectedId={setSelectedId}
                  setOpenWarn={setOpenWarn}
                  listedPost={listedPost}
                  handleFav={handleFav}
                />
              </div>
            </div>
            <div className="plateDetail">
              <div className="location">
                <figure>
                  <img src="/static/images/location.svg" alt="" />
                </figure>
                <h3>{listedPost?.postLocation || "N/A"}</h3>
              </div>
              <div className="des">
                <p>{listedPost?.meaningOfPlate || ""}</p>
              </div>
              {state === "AUCTION" ? (
                <div className="time">
                  <h4>
                    {translation.posts.auctionStartDate}{" "}
                    <span className="">
                      {moment(listedPost?.bidingStartTime).format("YYYY-MM-DD")}{" "}
                      to{" "}
                      {moment(listedPost?.bidingEndTime).format("YYYY-MM-DD")}
                    </span>
                  </h4>

                  <h4>
                    {translation.auctionTimes}{" "}
                    <span className="">
                      {moment(listedPost?.startTime, "HH:mm").format("hh:mm A")}{" "}
                      to{" "}
                      {moment(listedPost?.endTime, "HH:mm").format("hh:mm A")}
                    </span>
                  </h4>
                  {listedPost?.userId === user?._id ? (
                    <h4>
                      {translation.posts.status}
                      <span style={{ color: "rgb(60, 130, 200)" }}>
                        {ServiceStatus[Number(listedPost?.status)]}
                      </span>
                    </h4>
                  ) : null}
                </div>
              ) : null}

              {state === "AUCTION" ? null : (
                <>
                  {listedPost?.serviceDetails?.length ? (
                    <h2>{translation.posts.statusofplate}</h2>
                  ) : null}
                  <ul>
                    {listedPost?.serviceDetails?.length
                      ? listedPost?.serviceDetails?.map((item) => (
                          <li>{item?.serviceName || ""}</li>
                        ))
                      : null}

                    {listedPost?.violationAmount ? (
                      <p style={{ color: "#808080f7" }}>
                        {translation.posts.amountPaid}{" "}
                        {listedPost?.violationAmount || 0}{" "}
                        {translation.Globals.sar}
                      </p>
                    ) : null}
                  </ul>
                </>
              )}

              {listedPost?.services?.length &&
              listedPost?.userId !== user?._id ? (
                <>
                  <h2>{translation.posts.serviceSeller}</h2>
                  <ul>
                    {listedPost?.services?.length
                      ? listedPost?.services?.map((item) => (
                          <li>{item?.serviceType || ""}</li>
                        ))
                      : null}
                  </ul>
                </>
              ) : null}
            </div>

            <div className="wrapper">
              {listedPost?.role === "OFFER" ? (
                <h3
                  onClick={() => {
                    !user
                      ? dispatch(
                          toggleAuthModal({
                            isAuthModalVisible: true,
                            ModalType: "login",
                          })
                        )
                      : navigate(`/view-offer/${listedPost?._id}`, {
                          state: listedPost?.offerPosts,
                        });
                  }}
                >
                  {translation.posts.viewOffer}
                </h3>
              ) : state === "AUCTION" ? (
                <h3
                  onClick={() =>
                    navigate(`/bidders-list/${listedPost?._id}`, {
                      // state: listedPost?.offerPosts,
                    })
                  }
                >
                  {translation.posts.seeBidder}
                </h3>
              ) : null}

              {listedPost?.userfullName &&
              (listedPost?.role === "SELL" ||
                state === "AUCTION" ||
                listedPost?.role === "AUCTION") &&
              listedPost?.userId !== user?._id ? (
                <div className="sellerdiv">
                  <h4>{translation.posts.sellerDetail}</h4>
                  <div className="seller">
                    <div className="box">
                      <figure>
                        <img
                          style={{ borderRadius: "100%" }}
                          src={
                            listedPost?.userImage ||
                            "/static/images/userDiv.png"
                          }
                        />
                      </figure>
                      <div className="sellerName">
                        <div className="name">
                          <h2>{listedPost?.userfullName || ""}</h2>
                          <div className="star">
                            <figure>
                              <img src={"/static/images/star.png"} />
                            </figure>
                            <h2>{listedPost?.ratingCount || 0}</h2>
                          </div>
                        </div>
                        <p>{listedPost?.address || ""}</p>
                      </div>
                    </div>
                    {state === "AUCTION" || listedPost?.role === "AUCTION" ? (
                      <div className="btn-div">
                        <Button
                          value={translation.posts.startBidding}
                          onClick={() => {
                            if (isTimeBetween()) {
                              handleStartBid(listedPost?.isTokenMoneyPaid);
                            } else {
                              errorToast("You cannot bid at this time");
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="btn-div">
                        <Button
                          value={translation.posts.viewDetails}
                          onClick={() => {
                            if (user) {
                              navigate(`/seller-detail/${listedPost?.userId}`);
                            } else {
                              dispatch(
                                toggleAuthModal({
                                  isAuthModalVisible: true,
                                  ModalType: "login",
                                })
                              );
                            }
                          }}
                        />
                        {state === "SELL" || listedPost?.role === "SELL" ? (
                          <Button
                            value={translation.posts.chatNow}
                            onClick={() => {
                              if (user) {
                                navigate(`/chat`, {
                                  state: {
                                    type: "chat",
                                    roomId: listedPost?._id,
                                    senderId: listedPost?.userId,
                                    senderImage: listedPost?.userImage,
                                    senderName: listedPost?.userfullName,
                                  },
                                });
                              } else {
                                dispatch(
                                  toggleAuthModal({
                                    isAuthModalVisible: true,
                                    ModalType: "login",
                                  })
                                );
                              }
                            }}
                          />
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              {user?._id === listedPost?.userId ? (
                <div className="btns-like">
                  <p>{translation.posts.likesComment}</p>
                  <div className="like-parent">
                    <div className="dd">
                      <ThumbUpAltIcon />
                      <h5>{listedPost?.toallikes || "0"}</h5>
                    </div>
                    <div className="dd">
                      <ModeCommentIcon />{" "}
                      <h5>{listedPost?.toalComment || "0"}</h5>
                    </div>
                  </div>
                  <div className="comment_card2">
                    {listedPost?.comments?.length
                      ? listedPost?.comments?.map((item) => (
                          <div className="comment_section">
                            <p>{item?.fullName || ""}</p>
                            <p className="message">{item?.comment || ""}</p>

                            <div className="subComments">
                              {item?.subComments?.length
                                ? item?.subComments?.map((item) => (
                                    <h3 key={item?._id}>
                                      {item?.subCommentDetails?.fullName || ""}{" "}
                                      : <span> {item?.comment || ""} </span>
                                    </h3>
                                  ))
                                : null}
                            </div>
                            <p
                              className="reply"
                              onClick={() => {
                                setSelectedMsg(item?._id);
                                handleClickOpen();
                              }}
                            >
                              {translation.posts.reply}
                            </p>

                            <div className="horizontal_line" />
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              ) : null}

              {user && user?._id === listedPost?.userId ? (
                <div className="viewoffer">
                  {listedPost?.isAdvertisement ? null : (
                    <Button
                      value={translation.posts.advertisePost}
                      onClick={handleAdvertisePost}
                    />
                  )}

                  {state === "AUCTION" ? null : (
                    <Button
                      onClick={() => setOpenClose(true)}
                      value={
                        listedPost?.status === 6
                          ? translation.posts.alreadyClosed
                          : translation.posts.closePost
                      }
                      disabled={listedPost?.status === 6 ? true : false}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <PayNowModal
        open={open}
        setOpen={setOpen}
        tokenPrice={tokenPrice}
        payTokenMoney={payTokenMoney}
      />
      <WarnModal
        open={openWarn}
        setOpen={setOpenWarn}
        handleDelete={() => handleDeleteProduct(selectedId)}
        name="post"
      />

      <Modal
        open={openClose}
        onClose={() => setOpenClose(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon
                style={{ color: "red" }}
                onClick={() => {
                  setOpenClose(false);
                  setSold(false);
                }}
              />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              {translation.whyclose}
            </h2>
            {sold ? (
              <p className="counter" style={{ textAlign: "center" }}>
                {translation.closePostText}{" "}
                {countDown < 10 ? `00: 0${countDown}` : `00: ${countDown}`}
              </p>
            ) : null}
            <div className="flexDiv" style={{ width: "60%" }}>
              <div style={{ marginRight: 15, width: "48%" }}>
                <Button
                  value={translation.sold}
                  onClick={() => {
                    setSold(true);
                    setCountDown(20);
                  }}
                />
              </div>
              <div style={{ width: "48%" }}>
                <Button
                  value={translation.unsold}
                  onClick={() => handleClosePost(String(listedPost?._id))}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Dialog
        // style={{ width: 400 }}
        open={openComment}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        {/* <DialogTitle>Subscribe</DialogTitle> */}
        <DialogContent>
          <DialogContentText style={{ paddingRight: "250px" }}>
            {translation.posts.writeReply}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="email"
            label={translation.posts.reply}
            type="text"
            fullWidth
            variant="standard"
            value={subReply}
            onChange={(e) => setSubReply(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            value={translation.Globals.cancel}
            onClick={handleClose}
          ></Button>
          <Button
            value={translation.posts.reply}
            onClick={() => handleAddSubComment(id, subReply, selectedMsg)}
          />
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default PlateDetail;
