export const ar = {
  Globals: {
    negotiate: "ماسيمت",
    fixedPrice: "تم الإصلاح",
    fixedNego: "ثابت وتفاوض",
    customPrice: "سعر مخصص",
    agree: " موافق ",
    termsConditions: "البنود و سياسة الاستخدام",
    cancel: "يلغي",
    lastBid: "العطاء الاخير",
    won: "فاز",
    loose: "مرتخي",
    comment: "جار التنفيذ",
    addComment: "اضف تعليق",
    enterPrice: "أدخل السعر",
    name: "الاسم",
    rating: "التقيم",
    auction: "مزاد علني",
    enable: "تفعيل",
    today: "اليوم",
    yesterday: "البارحة",
    pending: "قيد الانتظار",
    ongoing: "جار التنفيذ",
    past: "سابق",
    Login: "تسجيل الدخول",
    sign_up: "إنشاء حساب",
    email: "البريد الإلكتروني",
    phone_number: "رقم الهاتف",
    full_name: "الاسم الكامل",
    userName: "اسم المستخدم",
    user_name: "اسم المستخدم",
    address: "العنوان",
    submit: "تقديم",
    profile: "الملف الشخصي",
    chat: "محادثة",
    wishlist: "المفضلة",
    notifications: "إشعارات ",
    logout: "تسجيل الخروج",
    categories: "الفئات ",
    see_all: "شاهد الكل",
    select: "إختر",
    no_info: "لم يتم العثور على معلومات",
    no_faq: "لم يتم العثور على قائمة الأسئلة الشائعة",
    message: "رسالة",
    price: "السعر",
    apply: "قدم",
    no_recent_notifications: "لم يتم العثور على إشعارات حديثة",
    older_notifications: "إشعارات قديمه",
    no_older_notifications: "لم يتم العثور على إشعارات قديمه",
    clear_notifications: "محو كافة الإشعارات ",
    all: "الكل",
    yes: "نعم",
    no: "لا",
    save: "حفظ",
    back: "العودة للخلف",
    update: "تحديث",
    add: "اضافة",
    location: "الموقع",
    edit_detail: "تعديل التفاصيل",
    for: "ل",
    expiry_date: "تاريخ الانتهاء",
    delete_err: "هل أنت متأكد من حذف هذا؟",
    invalidImg: "نوع الملف غير صالح. يرجى تحميل الصور فقط.",
    apply_filter: "تطبيق المرشحات",
    sar: "ر.س",
    how_can_we_help: "كيف يمكننا المساعدة",
    contact_us: "اتصل بنا",
    contact_now: "اتصل الآن",
    violationAmount: "مبلغ المخالفة",
  },
  Auth: {
    Login: {
      remember: "تذكيري",
      forgot_pas: "نسيت كلمة السر؟",
      email_id: "البريد الإلكتروني",
      password: "كلمة السر",
      logintoLowhate: "سجّل الدخول إلى لوحتي",
      signup: "اشتراك",
      not_member: "ليس عضوا",
      sign_up_with_email: "تسجيل الدخول باستخدام البريد الإلكتروني",
      sign_up_with_phone: "تسجيل الدخول برقم الهاتف",
    },
    Sign_up: {
      register: "سجل",
      welcome: "اهلآ بالمستخدم",
      confirm_password: "تأكيد كلمة المرور",
      sign_up_with: "تسجيل الدخول بواسطة",
      countryCode: "الرقم الدولي",
      selectCountry: "اختر البلد",
      alreadyHaveAcc: "هل لديك حساب؟",
    },
    forgot_password: {
      enter_email: "ادخل بريدك الإلكتروني هنا",
    },
    otp_verification: {
      verification: "التحقق",
      email_code: "لقد قمنا بإرسال رمز التحقق الى بريدك الإلكتروني",
      phone_code: "لقد قمنا للتو بإرسال رمز التحقق عبر ",
      resend: "إعادة إرسال",
      code_expire: "ستنتهي صلاحية رمز التحقق خلال ",
      enterOtp: "أدخل كلمة المرور المؤقتة الخاصة بك",
      otp: "أدخل رمز التأكيد",
      dontGetOtp: "لم تحصل على الرمز؟",
      sendOtp: "إرسال كلمة مرور مؤقتة",
    },
    profile_setup: {
      setup: "إعداد الملف الشخصي",
    },
    reset_password: {
      reset: "إعادة تعيين كلمة السر",
      new_password: "كلمة السر الجديدة",
    },
    change_password: {
      change: "تغير كلمة السر",
      old_pass: "كلمة السر القديمة",
      new_pass: "كلمة المرور الجديدة",
    },
  },
  header: {
    home: "الرئيسية",
    services: "خدمات",
    bids: "عروض الأسعار الخاصة بي",
    sell: "بيع",
    login: "تسجيل الدخول",
    search: "بحث",
    postSell: "نشر للبيع",
    postAuction: "أضف للمزاد",
    postOffer: "مشاركة للعرض",
    arabic: "عربي",
    profile: "حسابي",
    chat: "محادثة",
    notifications: "الاشعارات",
    logout: "تسجيل خروج",
    logoutWarning: "هل انت متاكد من تسجيل الخروج ",
    yes: "نعم",
    no: "لا",
  },
  footer: {
    support: "الدعم",
    contact_us: "اتصل بنا",
    faq: "الاسئلة الشائعة",
    about_us: "من نحن",
    privacy: "سياسة الخصوصية",
    terms: "الشروط والأحكام",
    follow_us: "تابعنا",
    subscribe: "إشتراك",
    enter_email: "ادخل الإلكتروني",
    lowhate: "لوحتي",
    rights_reserved: "جميع الحقوق محفوظة",
    categories: "فئات",
    pickupCar: "سيارات عمومية",
    publicCar: "سيارات الحمل",
    side_text:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
  },
  home: {
    download: "حمل تطبيق لوحتي",
    buySell:
      "قم بشراء أو بيع أي شيء باستخدام التطبيق على هاتفك المحمول. ابحث عن الوظائف والمنازل والخدمات والمزيد.",
    binary: "الثنائية",
    auctionPost: "تواصل مع البائع",
    offerPost: "كم تتوقع سعرها ؟",
    sellPosts: "بيع المشاركات",
    latesPost: "أحدث الإعلانات",
    mostLiked: "أكثر المنشورات إعجابًا",
    quadruple: "رباعي",
    triple: "ثلاثي",
    oddNumber: "الأعداد الفردية",
    seeAll: "اظهار الكل",
    searchText: "اكتب حروف اللوحة الثلاثة باللغة العربية ",
    seearchText1: "اكتب رقم اللوحة المكون من اربعة أرقام",
    result: "Results",
    notifyMe: "أعلمني",
    notifyMsg: "سنقوم بإعلامك بمجرد توفر لوحة الأرقام",
    available: "متوفر؟",
    soryBy: "ترتيب حسب",
    filters: "المرشحات",
    priceRange: "نطاق السعر",
    category: "الفئة",
    clearFilter: "مسح عوامل التصفية",
  },
  error: {
    noData: "لاتوجد بيانات",
    noBidders: "لا يوجد مزايدين حتى الآن",
    noservice: "لم يتم تحديد أي خدمة",
    noAgent: "لم يتم العثور على وكلاء",
  },
  profile: {
    new_card: "اضافة بطاقة جديدة",
    pay_via: "حفظ والدفع عبرالبطاقة ",
    enter_details: "ادخل تفاصيل بطاقتك ",
    holder_name: "اسم حامل البطاقة ",
    card_number: "رقم البطاقة",
    cvv: "رمز التحقق من البطاقة",
    save_card: "حفظ هذه البطاقة للدفعات المستقبلية؟",
    add_address: "اضف عنوان جديدآ",
    edit_address: "تعديل العنوان",
    house: "بيت / شقة / رقم المبنى",
    landmark: "علامة مميزة",
    save_As: "احفظ كـ",
    home: "الرئيسية",
    work: "العمل",
    other: "اخر",
    edit: "تعديل",
    no_address: "لم يتم العثور على العنوان",
    delete_address: "هل أنت متأكد أنك تريد حذف هذا العنوان؟",
    manage_profile: "إدارة تفاصيل حسابك الشخصي",
    invalid_email: "بريد إلكتروني غير صالح",
    verify: "تحقق",
    invalid_number: "رقم غير صالح",
    expiry_date: "تاريخ الانتهاء",
    description: "وصف",
    paymentHistory: "تاريخ الدفع",
    Weekly: "أسبوعي",
    Monthly: "شهريا",
    users: "users",
    myListing: "منشوراتي المدرجة",
    myAuctionPost: "منشور مزاداتي",
    manageAgent: "إدارة الوكلاء",
    manageAddress: "إدارة العناوين",
    managePayment: "إدارة الدفع",
    manageReview: "إدارة المراجعات",
    myEarning: "أجوري",
    myWishlist: "قائمة تفضيلاتي",
  },
  chat: {
    no_chats: "لم يتم العثور على محادثات",
    go_ahead: "إبداء المحادثه",
    type_message: "اكتب رسالة",
    sharedMedia: "ملف الوسائط المشترك",
    selectImgVdo: "الرجاء تحديد الصور ومقاطع الفيديو للتحميل",
  },
  validations: {
    required_field: "هذا الحقل مطلوب ",
    email_required: "البريد الإكتروني مطلوب",
    valid_email: "ادخل بريد إللكتروني صالح",
    password_required: "كلمة السر مطلوبة",
    password_validation:
      "يجب أن تحتوي على 8 أحرف أو أكثر، أحرف كبيرة واحدة، أحرف صغيرة واحدة، رقم واحد وحرف خاص واحد",
    password_match: "يجب ان تطابق كلمة السر",
    cofirmPass_required: "تأكيد كلمة المرور مطلوب.",
    images_allowed: "يسمح فقط بالصور",
    min_three: "مطلوب 3 احرف على الاقل ",
    min_two: "مطلوب 2 احرف على الاقل",
    max_Eighty: "الحد الاقصى المسموح به هوا 80 حرفآ",
    min_nine: "الحد الأدنى المطلوب هو 9 أحرف",
    max_fifteen: "الحد الأقصى المسموح به هو 15 حرفًا",
    old_pass_required: "كلمة السر القديمة مطلوبة",
    new_pass_required: "كملة السر الجديدة مطلوبة",
    new_pass_validation:
      "لا يمكن أن تكون كلمة السر الجديدة مماثلة لكلمة السر القديمة",
    card_holder_name: "يجب ان يحتوي اسم حامل البطاقة على ثلاثه احرف على الاقل ",
    phone_min: "يجب ان يحتوي رقم الهاتف على 6 ارقام على الاقل ",
    phone_max: "يجب ان تحتوي البطاقة  على 16 رقم على الاقل",
    cardNo_val: "يجب أن لا يقل رقم البطاقة عن 16 حرفًا",
    cvv_val: "يجب أن يتكون رمز CVV من 3 أحرف على الأقل",
  },
  toast_messages: {
    setup_complete: "تهانينا! تم اكتمال ملفك الشخصي بنجاح",
    password_updated: "تم تحديث كلمة السر بنجاح ",
    try_again: "يرجى المحاولة مرة اخرى",
    address_deleted: "تم حذف العنوان بنجاح",
    address_added: "تم أضافة العنوان بنجاح",
    address_updated: "تم تحديث العنوان بنجاح",
    postUpdated: "تم تحديث المنشور بنجاح",
    postAdded: "تمت إضافة المنشور بنجاح",
    removePost: "لقد قمنا بإزالة مشاركتك",
    postdeleted: "تم حذف المنشور بنجاح",
    thankyouMsg: "شكرا لك للاستخدام تطبيق لوحتي",
    commentAdded: "تمت إضافة التعليق بنجاح",
    selectAgent: "حدد الوكيل أولاً",
    delete_agent: "تم حذف العميل بنجاح",
    agent_add: "تمت إضافة العميل بنجاح",
    agent_update: "تم تحديث الوكيل بنجاح",
    otpResend: "تم إعادة إرسال OTP بنجاح",
    subscriptionAdd: "تم شراء الاشتراك بنجاح",
    profile_updated: "تحديث الملف الشخصي",
  },
  posts: {
    sellPostUpdate: "تحديث لوحة للبيع",
    sellPostAdded: "إضافة لوحة للبيع",
    postTitle: "عنوان المنشور",
    carPlateNum: "رقم لوحة سيارة",
    meaningOfPlate: "معنى لوحة السيارة (اختياري)",
    serviceAgent: "الخدمة والوكلاء",
    disclaimer:
      "لا يمكن تغيير العنوان ورقم لوحة السيارة ومعنى لوحة السيارة لاحقًا.",
    paySubmit: "الدفع والإرسال",
    auctionPostUpdate: "تحرير المشاركة للمزاد",
    auctionPostAdd: "أضف منشور للمزاد",
    pricePlate: "سعر لوحة السيارة",
    startedBid: "بدء المزايدة",
    bidStartDate: "تاريخ بدء المزايدة",
    offerPostUpdate: "تحديث المنشور للعرض",
    offerPostAdd: "أضف مشاركة للعرض",
    carPlateDetail: "تفاصيل لوحة السيارة",
    postedOn: "تم نشره في",
    startedFrom: "بدءا من",
    auctionStartDate: "تاريخ ووقت بدء المزاد",
    endAuction: "وقت انتهاء المزاد: ",
    status: "حالة :",
    statusofplate: "حالة لوحة السيارة",
    amountPaid: "المبلغ المدفوع",
    serviceSeller: "الخدمات المقدمة من البائع",
    viewOffer: "عرض العروض",
    seeBidder: "رؤية جميع مقدمي العروض",
    sellerDetail: "تفاصيل البائع",
    chatNow: "الدردشة الآن",
    likesComment: "الإعجابات والتعليقات",
    reply: "رد",
    writeReply: "اكتب ردا",
    startBidding: "بدء المزايدة",
    viewDetails: "عرض التفاصيل",
    payTokenMoney: "دفع مبلغ للمزايدة",
    payTokenSell: "ادفع أموالًا رمزية مقابل بيع المنشور",
    addCard: "إضافة بطاقة جديدة",
    amountRefund: "هذا المبلغ قابل للاسترداد",
    startingFrom: "بدءا من",
    closePost: "إزالة المشاركة",
    alreadyClosed: "تمت إزالتها بالفعل",
    advertisePost: "يعلن",
  },
  bids: {
    myBids: "العطاءات الخاصة بي",
    bidDetail: "تفاصيل العطاء",
    bidders: "المزايدون",
    currentBid: "المزايدة الحالية",
    bidOutBid: "لقد تم المزايدة على لوحتك",
    increaseMaxBid: "رفع سقف المزايدة",
    placeBid: " ضع مزايدة",
    weBid: "سنقوم بالمزايدة نيابة عنك ، لحد..",
    disclaimer: "من خلال المزايدة، فإنك توافق على دفع المبلغ للبائع إذا فزت.",
    placeBid1: "وضع عرض",
    orhigher: "أو أعلى",
    addOffer: "إضافة سعر العرض",
    madeOffer: "لقد قدمت عرضا",
    addOffer1: "أضف العرض",
    anonymousoffer: "مجهول المقدمة",
  },
  service: {
    addPrice: "أضف سعرًا للخدمة",
    addService: "إضافة خدمة",
    addAgent: "إضافة وكيل",
    chooseAgent: "اختر الوكلاء",
    addNewAgent: "إضافة وكيل جديد",
    agentName: "اسم الوكيل",
    agent: "عامل",
  },
  subscriptions: {
    subscription: "اشتراك",
    skip: "يتخطى",
    month: "شهر",
    months: "شهور",
    year: "سنة",
    for: "ل",
  },
  auctionTeaser: "إعلان تشويقي للمزاد",
  okay: "OK",
  enterValidDate: "الرجاء إدخال تواريخ صالحة",
  auctionTimes: "مواعيد المزاد",
  sold: "تم البيع",
  unsold: "غير مباع",
  whyclose: "لماذا تغلق هذا المنشور؟",
  closePostText: "سيتم إغلاق مشاركتك في",
};
