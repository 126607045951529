import React, { SyntheticEvent, useEffect, useState } from "react";
import { BookingCard, CarPlateCard, InputField } from "../../components";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import cardData from "../../data/ProductData.json";
import {
  useLazyGetUserAuctionPostQuery,
  useLazyGetUserSellPostQuery,
} from "../../service/posts";
import { ListedPost } from "../../types/General";
import { getFromStorage, Loader, STORAGE_KEYS } from "../../helpers";
import useTranslation from "../../hooks/Translation";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type props = {
  activeCase?: number;
};

const MyAuctionPost = ({ activeCase }: props) => {
  const translation = useTranslation();
  const language = getFromStorage(STORAGE_KEYS.language);

  const [auctionPost, { isLoading }] = useLazyGetUserAuctionPostQuery();
  const [allListedPost, setAllLitedPost] = useState<ListedPost[]>([]);
  const [favItems, setFavItems] = useState<any[]>([]);
  const [likeItems, setLikeItems] = useState<any[]>([]);

  const [value3, setValue3] = useState(0);

  const handleChange3 = (event: SyntheticEvent, newValue: number) => {
    setValue3(newValue);
  };

  const getUserListedPost = async () => {
    try {
      const response = await auctionPost({ status: value3 }).unwrap();

      if (response?.statusCode === 200) {
        setAllLitedPost(response?.data?.auction || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserListedPost();
  }, [value3]);

  return (
    <div className="main">
      <Loader isLoad={isLoading} />
      <Box className="cards_header">
        {/* ll */}
        <Box sx={{ width: "100%", marginBottom: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value3}
              onChange={handleChange3}
              aria-label="basic tabs example"
            >
              <Tab label={translation.Globals.pending} {...a11yProps(0)} />
              <Tab label={translation.Globals.ongoing} {...a11yProps(2)} />
              <Tab label={translation.Globals.past} {...a11yProps(3)} />
            </Tabs>
          </Box>
        </Box>
        {/* // */}
        <br />
      </Box>
      <div className={allListedPost?.length ? "crds_sec" : "crds_sec_noData"}>
        {allListedPost?.length ? (
          allListedPost?.map((item) => (
            <CarPlateCard
              activeCase={activeCase}
              posts
              category={
                language === "en"
                  ? item?.categoryData?.categoryName || ""
                  : item?.categoryData?.categoryName_ar || ""
              }
              posttitle={item?.posttitle}
              price={item?.price}
              carPlatenumber={item?.carPlatenumber}
              _id={item?._id}
              setFavItems={setFavItems}
              favItems={favItems}
              item={item}
              likeItems={likeItems}
              setLikeItems={setLikeItems}
              role="AUCTION"
            />
          ))
        ) : (
          <div className="noData">
            <figure>
              <img src="/static/images/noData.webp" alt="" />
            </figure>
            <h3> {translation.error.noData}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAuctionPost;
