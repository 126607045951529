import { TextField } from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";

const Footer = () => {
  const navigate = useNavigate();

  const translation = useTranslation();
  return (
    <footer className="ftr">
      <div className="ftr_top">
        <ul className="container mn_ftr">
          <li>
            <figure className="logoImg">
              <img src="/static/images/logo_nw.png" />
            </figure>
            <p>{translation.footer.side_text}</p>
          </li>
          <li>
            <h4> {translation.footer.categories}</h4>
            <h6> {translation.footer.publicCar}</h6>
            <h6> {translation.footer.pickupCar}</h6>
          </li>
          <li>
            <h4>{translation.footer.support}</h4>
            <h6 onClick={() => navigate("/contact-us")}>
              {translation.footer.contact_us}
            </h6>
            <h6>{translation.footer.faq}</h6>
            <h6 onClick={() => navigate("/terms-&-conditions")}>
              {translation.Globals.termsConditions}
            </h6>
            <h6 onClick={() => navigate("/privacy-policy")}>
              {translation.footer.privacy}
            </h6>
          </li>
          <li>
            <h4>{translation.footer.follow_us}</h4>
            <div className="scl_icns">
              <figure>
                <img src="/static/images/facebook.png" />
              </figure>
              <figure>
                <img src="/static/images/instagram.png" />
              </figure>
              <figure>
                <img src="/static/images/linkedin.png" />
              </figure>
            </div>
            <div className="subscribe_ftr">
              <h4>{translation.footer.subscribe}</h4>
              <div className="sub_btn">
                <TextField
                  // value={value}
                  // onChange={onChange}
                  className="ftr_txtfld"
                  hiddenLabel
                  placeholder={translation.footer.enter_email}
                  fullWidth
                  variant="outlined"
                />
                <button>{translation.footer.subscribe}</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="ftr_btm">
        <p>
          © {translation.footer.lowhate} {moment().format("YYYY")},{" "}
          {translation.footer.rights_reserved}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
