import React, { useEffect, useState } from "react";
import cardData from "../../data/ProductData.json";
import { CarPlateCard } from "../../components";
import { useNavigate } from "react-router-dom";
import { Dashboard } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import { getFromStorage, STORAGE_KEYS } from "../../helpers";

type props = {
  dashboardData: Dashboard | null;
  loading: boolean;
};

const MostLiked = ({ dashboardData, loading }: props) => {
  const language = getFromStorage(STORAGE_KEYS.language);

  const translation = useTranslation();
  const navigate = useNavigate();
  const [favItems, setFavItems] = useState<any[]>([]);
  const [likeItems, setLikeItems] = useState<any[]>([]);

  const checkFav = () => {
    const binaryData = dashboardData?.mostLikes || [];
    const fav_data = binaryData
      .filter((item: any) => item?.isWishlistByMe)
      .map((item: any) => item);
    const like_data = binaryData
      .filter((item: any) => item?.isLikedByMe)
      .map((item: any) => item);
    setLikeItems(like_data);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFav();
  }, [dashboardData?.mostLikes]);
  return (
    <section className="prodcts_sc container">
      <div className="prdct_hdngs ">
        <h2>{translation?.home.mostLiked}</h2>
        {dashboardData?.mostLikes?.length &&
        dashboardData?.mostLikes?.length > 5 ? (
          <p
            onClick={() =>
              navigate("/see-all", {
                state: {
                  key: translation.home.mostLiked,
                  type: "mostLike",
                },
              })
            }
          >
            {translation?.home.seeAll}
          </p>
        ) : null}
      </div>

      <div className="crds_sec">
        {!loading && !dashboardData?.mostLikes?.length ? (
          <div className="noData">
            <figure>
              <img src="/static/images/noData.webp" alt="" />
            </figure>
            <h3>{translation?.error.noData}</h3>
          </div>
        ) : dashboardData?.mostLikes?.length ? (
          dashboardData?.mostLikes
            ?.slice(0, 5)
            .map((item) => (
              <CarPlateCard
                category={
                  language === "en"
                    ? item?.categoryData?.categoryName || ""
                    : item?.categoryData?.categoryName_ar || ""
                }
                posttitle={item?.posttitle}
                price={item?.price}
                carPlatenumber={item?.carPlatenumber}
                _id={item?._id}
                setFavItems={setFavItems}
                favItems={favItems}
                item={item}
                likeItems={likeItems}
                setLikeItems={setLikeItems}
              />
            ))
        ) : null}
      </div>
    </section>
  );
};

export default MostLiked;
