import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import "../auth/Auth.scss";
import OTPInput from "react-otp-input";
import { toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch } from "../../hooks/store";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  closeModal: () => void;
  phone: boolean;
  signUp: boolean;
};

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
  paddingBotton: 20,
};

const Verification = ({ closeModal, phone, signUp }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = () => {
    closeModal();
    if (phone && !signUp) {
      navigate("/");
    } else if (signUp) {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "profileSetup" })
      );
    } else {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "reset" })
      );
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let newOtp = otp.split("");
    const value = e.target.value;

    // Check if input is a valid Arabic or English numeral (٠١٢٣٤٥٦٧٨٩ or 0-9)
    const allowedCharacters = /^[0-9\u0660-\u0669]$/;
    if (allowedCharacters.test(value)) {
      newOtp[index] = value;
      setOtp(newOtp.join(""));
    }
  };

  const handleFocus = (e: { target: { select: () => void } }) => {
    e.target.select();
  };
  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <div>
      <div className="AuthWrap">
        <div className="cross">
          <CloseIcon />
        </div>
        <h2>Verification</h2>

        {phone ? (
          <p>
            We have sent you SMS with a code to phone number{" "}
            <span>+91 84984-65828</span>
          </p>
        ) : (
          <p>
            We have sent you an SMS with a code to email{" "}
            <span>admin@apptunix.com</span>
          </p>
        )}

        {/* <InputField placeholder="Email Id" />
         */}
        <FormControl className="opt_fields" sx={{ width: "100%" }}>
          <OTPInput
            value={otp}
            onChange={(value) => {
              const allowedCharacters = /^[0-9\u0660-\u0669]*$/;
              if (value === " " || value === ".") {
              } else if (allowedCharacters.test(value)) {
                setOtp(value);
              }
            }}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            inputStyle={otpStyle}
            inputType="text"
          />

          <br />
          {error && otp.length !== 4 ? (
            <h6 className="err_msg">This field is required</h6>
          ) : (
            ""
          )}
        </FormControl>

        <Button value="Next" onClick={handleSubmit} />
        <div className="continue">
          <h5>The verify code will expire in 00 : {countDown}</h5>
        </div>
        <div className="resend">
          <h4>Resend Code</h4>
        </div>
      </div>
    </div>
  );
};

export default Verification;
